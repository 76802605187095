/**
 * GSKePan
 * GSCheck.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:28
 * @desc:   GSCheck
 **/
'use strict';


/**
 * 判断是否为手机号
 */
export const isPhoneNumber = (str) => {
    if (isEmptyString(str)) {
        return false;
    }
    const reg = /^[1][0-9]{10}$/;
    return reg.test(str)
};


/**
 * 判断变量是否为空
 */
export const isNull = (obj) => {
    if (obj === undefined) {
        return true;
    }
    if (obj === null) {
        return true;
    }
    return false;
};


/**
 * 判断字符串是否为空
 */
export const isEmptyString = (str) => {
    if (isNull(str)) {
        return true;
    }
    if (typeof str !== 'string') {
        return true;
    }
    if (str.length === 0) {
        return true;
    }
    return false;
};

/**
 * 判断对象是否为空
 */
export const isEmptyObject = (obj) => {
    if (isNull(obj)) {
        return true;
    }
    if (typeof obj !== 'object') {
        return true;
    }
    for(let o in obj){
        return false
    }
    return true;
};
