/**
 * GSKePan
 * GSBranchSearchPage.js
 *
 * @author: zengzhiming
 * @date:   2023/3/14
 * @desc:   GSBranchSearchPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    TextInput,
    FlatList,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import {LanguageType} from "../../Common/Languages/GSLanguages";

class GSBranchSearchPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            keyword:"",
            branchList:[],
        };
    }

    componentDidMount() {
        this.getBranchList(this.props["language"]);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps["language"] !== this.props["language"]){
            this.getBranchList(nextProps["language"]);
            return false;
        }
        return true;
    }

    getBranchList = (language) => {
        let branchList = (language === LanguageType.ZH_HANT)
            ? [{"branchName":"合肥逍遙廣場【AH4】","branch":"AH4"},{"branchName":"合肥宿州路商之都【AH5】","branch":"AH5"},{"branchName":"合肥包河蘇寧易購【AH6】","branch":"AH6"},{"branchName":"合肥百貨大樓【AH7】","branch":"AH7"},{"branchName":"無為米市街【AH9】","branch":"AH9"},{"branchName":"合肥天鵝湖蘇寧易購【AHA】","branch":"AHA"},{"branchName":"馬鞍山金鷹【AHB】","branch":"AHB"},{"branchName":"合肥大洋百貨【AHC】","branch":"AHC"},{"branchName":"宿州蘇寧廣場【AHD】","branch":"AHD"},{"branchName":"銅陵中央商場【AHE】","branch":"AHE"},{"branchName":"揚州江都金鷹【AHF】","branch":"AHF"},{"branchName":"淮北鼎盛國際【AHH】","branch":"AHH"},{"branchName":"亳州太陽珠寶城【AHI】","branch":"AHI"},{"branchName":"蒙城縣寶塔中路店【AHJ】","branch":"AHJ"},{"branchName":"渦陽縣向陽路【AHK】","branch":"AHK"}]
            : [{"branchName":"合肥逍遥广场【AH4】","branch":"AH4"},{"branchName":"合肥宿州路商之都【AH5】","branch":"AH5"},{"branchName":"合肥包河苏宁易购【AH6】","branch":"AH6"},{"branchName":"合肥百货大楼【AH7】","branch":"AH7"},{"branchName":"无为米市街【AH9】","branch":"AH9"},{"branchName":"合肥天鹅湖苏宁易购【AHA】","branch":"AHA"},{"branchName":"马鞍山金鹰【AHB】","branch":"AHB"},{"branchName":"合肥大洋百货【AHC】","branch":"AHC"},{"branchName":"宿州苏宁广场【AHD】","branch":"AHD"},{"branchName":"铜陵中央商场【AHE】","branch":"AHE"},{"branchName":"扬州江都金鹰【AHF】","branch":"AHF"},{"branchName":"淮北鼎盛国际【AHH】","branch":"AHH"},{"branchName":"亳州太阳珠宝城【AHI】","branch":"AHI"},{"branchName":"蒙城县宝塔中路店【AHJ】","branch":"AHJ"},{"branchName":"涡阳县向阳路【AHK】","branch":"AHK"}];
        this.setState({
            branchList: branchList,
        });
    }
    render() {

        const styles = this.getStyles();
        const {keyword, branchList} = this.state;
        const {Colors} = Theme;

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={styles.search_view}>
                            <View style={styles.search_content_view}>
                                <View style={styles.search_textinput_view}>
                                    <TextInput  style={styles.search_textinput}
                                                placeholder={T("请输入分行代码")}
                                                placeholderTextColor={Colors.login_brand_search_B2B2B2}
                                                value={keyword}
                                                onChangeText={(text)=>{
                                                    this.setState({
                                                        keyword:text
                                                    }) ;
                                                }}/>
                                </View>
                                <TouchableOpacity style={styles.search_btn}>
                                    <Text style={styles.search_btn_txt}>{T("搜索")}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <FlatList
                            data={branchList}
                            renderItem={({item})=>this.renderItem(item)}
                            keyExtractor={item => item["branch"]}
                        />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    renderItem(item){

        const styles = this.getStyles();
        const {branchName, branch} = item;
        return (
            <TouchableOpacity style={styles.item_view_btn}>
                <View style={styles.item_content_view}>
                    <Text style={styles.item_content_branchname}>{branchName}</Text>
                </View>
            </TouchableOpacity>
        );
    }

    getStyles() {

        const {Colors} = Theme;

        return StyleSheet.create({

            safe_area_view:{
                flex: 1,
                backgroundColor:Colors.theme_background,
            },

            container: {
                flex: 1
            },

            search_view: {
                padding:16,
                backgroundColor:Colors.login_brand_search_F4F6F8
            },

            search_content_view: {
                flexDirection:"row",
                borderRadius:8,
                overflow:"hidden",
            },

            search_textinput_view: {
                flex:1,
                backgroundColor:Colors.login_brand_search_EDECEC,
                overflow:"hidden"
            },

            search_textinput:{
                height:51,
                margin:-1,
                paddingHorizontal:8,
                fontSize:19,
                color:Colors.login_brand_search_000000
            },

            search_btn:{
                backgroundColor:Colors.theme_first,
                justifyContent:"center",
                alignItems: 'center',
                width:68
            },

            search_btn_txt:{
                color:Colors.login_brand_search_FFFFFF,
                fontSize:19
            },


            item_view_btn:{
                backgroundColor:Colors.theme_FFFFFF
            },

            item_content_view:{
                height:63,
                marginLeft:18,
                borderBottomWidth:0.5,
                borderBottomColor:Colors.login_brand_search_D7D7D7,
                justifyContent:"center"
            },

            item_content_branchname:{
                marginLeft:10,
                fontSize:19,
                color:Colors.theme_000000
            },

        })
    }
}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBranchSearchPage)


// export default GSBranchSearchPage;