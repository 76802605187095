/**
 * GSKePan
 * ReduxStore.js
 *
 * @author: zengzhiming
 * @date:   2021/10/14 16:12
 * @desc:   ReduxStore
 **/
'use strict';

import {configureStore } from '@reduxjs/toolkit'
import Reducer, {languageChange, themeChange} from './GSReducer';

export const reduxStore = configureStore({
    reducer: Reducer
})

export function languageChangeAction(payload) {
    reduxStore.dispatch(languageChange(payload));
}

export function themeChangeAction(payload) {
    reduxStore.dispatch(themeChange(payload));
}