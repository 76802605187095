/**
 * GSKePan
 * GSReducer.js
 *
 * @author: zengzhiming
 * @date:   2021/10/14 16:13
 * @desc:   GSReducer
 **/
'use strict';

import { createSlice } from '@reduxjs/toolkit'

const reduxSlice = createSlice({
    name: 'ReduxSlice',
    initialState: {
        language: "",
        theme:"",
    },
    reducers: {
        languageChange: (state, action) => {
            state.language = action.payload;
        },
        themeChange: (state, action) => {
            state.theme = action.payload;
        },
    }
})

export const { languageChange, themeChange } = reduxSlice.actions

export default reduxSlice.reducer;