/**
 * GSKePan
 * GSStringFormat.js
 *
 * @author: zengzhiming
 * @date:   2023/3/21
 * @desc:   GSStringFormat
 **/
'use strict';



/** 格式化货币格式
 *
 * number number 小数点位数
 * prefix string 前缀货币符号
 * separator string 分隔符
 * decimal int 小数保留位数
 */
export const formatCurrencyMine = (number) => {
    const prefix = getShare(ShareKey.Area) === "CN" ? "￥" : "$";
    return formatCurrency(number, prefix, ",", 0);
}


/** 格式化货币格式
 *
 * number number 小数点位数
 * prefix string 前缀货币符号
 * separator string 分隔符
 * decimal int 小数保留位数
 */
export const formatCurrency = (number, prefix, separator, decimal) => {

    let currencyNum = number+"";
    if (decimal != null) {
        currencyNum = parseFloat(currencyNum).toFixed(decimal);
    }
    if (separator != null) {
       const currencyArr = (currencyNum).split("\.");
       currencyNum = currencyArr[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1'+separator)
        if (currencyArr.length > 1) {
            currencyNum = currencyNum+"."+currencyArr[1];
        }
    }
    if (prefix != null) {
        currencyNum = prefix+currencyNum;
    }
    return currencyNum;
};



