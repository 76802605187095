/**
 * GSKePan
 * index.js
 *
 * @author zengzhiming
 * @date   2019/1/18
 **/
"use strict";


export const Images = {
    app_icon:require('./app_icon.png'),
    app_splash:require('./app_splash.png'),
    login_brand_bg:require('./login_brand_bg.png'),

    login_login_arrow_down:require('./login_login_arrow_down.png'),
    login_login_arrow_up:require('./login_login_arrow_up.png'),
    login_login_bg_def:require('./login_login_bg_def.png'),
    login_login_branch:require('./login_login_branch.png'),
    login_login_clear_circle:require('./login_login_clear_circle.png'),
    login_login_clear_select:require('./login_login_clear_select.png'),
    login_login_pwd:require('./login_login_pwd.png'),
    login_login_pwd_clear:require('./login_login_pwd_clear.png'),
    login_login_user_del:require('./login_login_user_del.png'),
    login_login_user:require('./login_login_user.png'),
    login_login_bank_right:require('./login_login_bank_right.png'),
    login_login_history_head:require('./login_login_history_head.png'),
    login_login_scan_box:require('./login_login_scan_box.png'),
    login_login_scan_line:require('./login_login_scan_line.png'),

    sacn_scancode_shopping:require('./sacn_scancode_shopping.png'),
    sacn_scancode_more:require('./sacn_scancode_more.png'),
    sacn_scancode_scan_line:require('./sacn_scancode_scan_line.png'),






};

export default Images;