/**
 * GSKePan
 * GSBasePage.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:17
 * @desc:   GSBasePage
 **/
'use strict';

import React, {Component} from 'react';

import {HeaderBackButton} from '@react-navigation/elements';

export default class GSBasePage extends Component {


    setNavi = (title = null, backTitle = null, onBackPress = null) => {
        title && this.setTitle(title);
        backTitle && this.setBackTitle(backTitle);
        onBackPress && this.setBackPress(onBackPress);
    };

    setTitle = (title) => {
        this.props.navigation.setOptions({title:title})
    };

    setBackTitle = (title) => {
        this.props.navigation.setOptions({headerBackTitle:title});
    }

    setHeaderShown = (isShown) => {
        this.props.navigation.setOptions({headerShown:isShown});
    }

    setBackPress = (onPress) => {
        this.props.navigation.setOptions({
            headerLeft: (props) => {
                return (
                    <HeaderBackButton {...props} onPress={onPress} />
                );
            },
        });
    }


    push = (sceneKey, data = {}) => {
        this.props.navigation.push(sceneKey, data);
    }

    navigate = (sceneKey, data = {}) => {
        this.props.navigation.navigate(sceneKey, data);
    }

    goBack = () => {
        this.props.navigation.goBack();
    }
}
