/**
 * GSKePan
 * GSLanguages.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:23
 * @desc:   GSLanguages
 **/
'use strict';

/**
 * 安装方法：
 *
 * yarn add i18n-js
 * #yarn add react-native-languages
 * #npx pod-install
 *
 * 详见【RN i18n多语言】
 *
 **/

import {languageTag} from "../Utils/GSLocalization";
import { I18n } from 'i18n-js';
import {addShare, ShareKey} from "../Storage/GSShare";

import zh_Hans from './zh-Hans.json';
import zh_Hant from './zh-Hant.json';

import {languageChangeAction} from '../Redux/GSReduxStore';

// 配置I18n
const i18n = new I18n({"zh-Hans": zh_Hans, "zh-Hant": zh_Hant});

// 支持语言定义
const LanguageType = {
    ZH_HANS:'zh-Hans', // 中文简体
    ZH_HANT:'zh-Hant', // 中文繁体
};

let currLanguage = "";

// 初始化语言
const initLanguage = () => {

    changeLanguage(languageTag);
}

// 修改语言
const changeLanguage = (language) => {
    currLanguage = language.includes(LanguageType.ZH_HANT) ? LanguageType.ZH_HANT : LanguageType.ZH_HANS;
    i18n.locale = currLanguage;
    addShare(ShareKey.LANGUAGE, currLanguage);
    languageChangeAction(currLanguage);
};




// 获取文本
const getText = (...params) => {
    if (params.length === 0) {
        return "";
    }
    let text = i18n.t(...params);
    if (text.match(/\[missing "(\S*)" translation\]/)) {
        text = params[0];
    }
    return text;
};


export {LanguageType, currLanguage, initLanguage, changeLanguage, getText};
