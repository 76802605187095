/**
 * GSKePan
 * GSScanCodeView.js
 *
 * @author: zengzhiming
 * @date:   2023/3/17
 * @desc:   GSScanCodeView
 **/
'use strict';

import React, {Component} from 'react';
import {
    StyleSheet,
    View,
    Image,
    Animated,
} from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import BaseView from "../../../Common/Component/BaseComponent/GSBaseView";

class GSScanCodeView extends BaseView {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            isShow: props["isShow"]||false,
            isScan: props["isScan"]||false,
        };

        this.initScanAnimated();
    }

    initScanAnimated = () => {

        this.data = {
            scanData : "",
            topValue : new Animated.Value(20),
            opacityValue : new Animated.Value(1),
        };
        this.scanAnimated =Animated.loop( Animated.stagger(1500,
            [
                Animated.timing(this.data.topValue, {
                    toValue: 300,
                    duration: 2000,
                    useNativeDriver: false,
                }),
                Animated.timing(this.data.opacityValue, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: false,
                })
            ]
        ));
    }

    componentDidMount() {
        const {isShow, isScan} = this.state;
        const {scRef} = this.props;
        scRef&&scRef(this);
        isShow && isScan && this.show();
    }

    show = async () => {
        console.log(1111);
        let permis = await BarCodeScanner.getPermissionsAsync();
        if (permis["status"] !== "granted") permis = await BarCodeScanner.requestPermissionsAsync();
        if (permis["status"] === "granted") {
            this.state["isShow"] = true;
            this.start();
        } else {
            alert("没有权限");
        }
    };

    hide = () => {
        this.state["isShow"] = false;
        this.pause();
    }

    start = () => {
        this.data["scanData"] = "";
        this.setState({
            isScan: true,
        });
        this.scanAnimated.start();
    };

    pause = () => {
        this.scanAnimated.stop();
        this.scanAnimated.reset();
        this.setState({
            isScan: false,
        });
    }


    onBarCodeScanned = (result) => {
        console.log("===>onBarCodeScanned:", result);
        const scanData = result["data"];
        if (!isEmptyString(scanData) && scanData !== this.data.scanData) {
            this.props.onBarCodeScanned && this.props.onBarCodeScanned(scanData);
            this.data["scanData"] = scanData;
            this.pause();
        }
    };


    render() {

        const {isShow, isScan} = this.state;
        if (!isShow){
            return;
        }
        const {style} = this.props;
        const {topValue, opacityValue} = this.data;

        const {Images, Colors} = Theme;
        const styles = this.getStyles();
        return (
            <View style={[styles.container, style]}>
                <View style={styles.scan_box_view}>
                    <BarCodeScanner
                        onBarCodeScanned={isScan ? this.onBarCodeScanned : null}
                        style={styles.scan_code_scanner}
                    />
                    {/*<Image style={styles.scan_code_img_box} resizeMode={"stretch"} source={Images.login_login_scan_box} />*/}
                    <Animated.Image style={[styles.scan_code_img_line,{top: topValue, opacity: opacityValue}]} resizeMode={"stretch"} source={Images.login_login_scan_line} />
                </View>
            </View>
        );
    }

    getStyles() {

        const {Colors} = Theme;

        return StyleSheet.create({

            container:{
                overflow:"hidden",
                position:"absolute",
                top:0,
                left:0,
                bottom:0,
                right:0,
            },

            scan_box_view: {
                flex:1,
                backgroundColor:Colors.login_login_000000
            },

            scan_code_scanner: {
                width:"100%",
                height:"100%"
            },

            scan_code_img_box:{
                position:"absolute",
                width:"100%",
                height:"100%"
            },

            scan_code_img_line:{
                position:"absolute",
                width:"100%",
                height:69
            },

        })
    }
}

export default GSScanCodeView;