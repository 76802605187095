/**
 * GSKePan
 * GSUserListView.js
 *
 * @author: zengzhiming
 * @date:   2023/3/20
 * @desc:   GSUserListView
 **/
'use strict';

import React, {Component} from 'react';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity, Image, FlatList,
} from 'react-native';
import BaseView from "../../../Common/Component/BaseComponent/GSBaseView";


class GSUserListView extends BaseView {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        const isShow = props["isShow"]||false;
        const dataSource = props["dataSource"]||[];

        this.state = {
            isShow: isShow,
            dataSource: dataSource,
        };
    }

    componentDidMount() {
        const {ulRef} = this.props;
        ulRef&&ulRef(this);
    }


    show = (dataSource) => {
        this.setState({
            isShow:true,
            dataSource:dataSource,
        });
    }


    hide = () => {
        this.setState({
            isShow:false,
        });
    }

    render() {

        const {isShow, dataSource} = this.state;
        if (!isShow){
            return;
        }

        const {style, onSelectedPress} = this.props;
        const styles = this.getStyles();

        return (
            <View style={[styles.container,style]}>
                <FlatList
                    data={dataSource}
                    keyExtractor={item => item["userName"]}
                    renderItem={(itemInfo)=>this.renderItem(itemInfo, onSelectedPress)}
                />
            </View>
        );
    }

    renderItem(itemInfo, onSelectedPress) {

        const {Colors, Images} = Theme
        const {item, index} = itemInfo;
        const styles = this.getStyles();

        return (
            <TouchableOpacity onPress={()=>{
                onSelectedPress && onSelectedPress(1, item);
                this.hide();
            }}>
                <View style={styles.item_content_view}>
                    <Image style={styles.item_content_small_icon} source={Images.login_login_history_head} />
                    <Text style={styles.item_content_text}>{item["userName"]}</Text>
                    <TouchableOpacity onPress={()=>{
                        onSelectedPress && onSelectedPress(2, item);
                        this.hide();
                    }}>
                        <Image style={styles.item_content_small_icon} source={Images.login_login_pwd_clear} />
                    </TouchableOpacity>
                </View>
            </TouchableOpacity>
        );
    }

    getStyles(){

        const {Colors} = Theme;

        return StyleSheet.create({
            container: {
                borderWidth: 2,
                borderColor: Colors.theme_first,
                backgroundColor: Colors.login_login_FFFFFF,
                overflow:"hidden",
                position:"absolute",
                top:0,
                left:0,
                bottom:0,
                right:0,
            },

            item_content_view:{
                flexDirection:"row",
                height:53,
                borderBottomWidth:1,
                borderBottomColor:"#D7D7D7",
                alignItems:"center",
                paddingHorizontal:8,
            },
            item_content_small_icon:{
                width: 28,
                height: 28,
            },
            item_content_text:{
                flex:1,
                marginLeft:8,
                color:"#C5C5C5",
                fontSize:19,
            },
        });
    }
}





export default GSUserListView;
