/**
 * GSKePan
 * GSRoot.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:41
 * @desc:   GSRoot
 **/
'use strict';

import 'react-native-gesture-handler';
import './GSGlobals'; // 注册全局变量

import { RootSiblingParent } from 'react-native-root-siblings';
import React, {Component} from 'react';
import {LogBox} from 'react-native';
import { Provider } from 'react-redux';
import {reduxStore} from '../Common/Redux/GSReduxStore'
import Navigation from './GSNavigation'


import * as SplashScreen from 'expo-splash-screen';

export default class GSRoot extends Component {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        // 继续显示启动页 Prevent native splash screen from autohiding before App component declaration
        SplashScreen.preventAutoHideAsync()
            .then(result => console.log(`SplashScreen.preventAutoHideAsync() succeeded: ${result}`))
            .catch(console.warn);

        // // 屏蔽指定黄色警告
        // LogBox.ignoreLogs([
        //     "[AsyncStorage] Using undefined type for key is not supported.",
        // ]);

        initLanguage();
        initTheme();

    }

    componentDidMount() {
        // 隐藏启动页 Hides native splash screen
        setTimeout(async () => {
            await SplashScreen.hideAsync();
        }, 0);


    }

    render() {
        return (
            <RootSiblingParent>
                <Provider store={reduxStore}>
                    <Navigation />
                </Provider>
            </RootSiblingParent>
        );
    }
}
