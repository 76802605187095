/**
 * GSKePan
 * MainTestPage.js
 *
 * @author: zengzhiming
 * @date:   2022/9/5 17:19
 * @desc:   MainTestPage
 **/
'use strict';

import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
} from 'react-native';


class GSTestPage extends Component {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }


    onPress = () => {
        alert('点击按钮');
    };

    render() {

        const styles = this.getStyles();

        return (
            <SafeAreaView style={{flex: 1}}>
                <View style={styles.container}>
                    <TouchableOpacity onPress={this.onPress}>
                        <Text>点击按钮</Text>
                    </TouchableOpacity>
                </View>
            </SafeAreaView>
        );
    }

    getStyles() {
        return StyleSheet.create({
            container: {
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            },
        })
    }
}





export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSTestPage)


// export default GSTestPage;
