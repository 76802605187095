/**
 * GSKePan
 * GSLog.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:29
 * @desc:   GSLog
 **/
'use strict';

export const GSLog = (...params) => {
    console.log(...params);
};

