/**
 * GSKePan
 * GSLoading.js
 *
 * @author: zengzhiming
 * @date:   2022/8/29 10:53
 * @desc:   GSLoading
 **/
'use strict';


import React from 'react';
import {StyleSheet, Text, View, ActivityIndicator} from 'react-native';
import RootSiblings from "react-native-root-siblings";


let sibling = null;

// 显示
export const showLoading = (title = '') => {
    closeLoading();
    sibling = new RootSiblings(
        <View style={styles.loading}>
            <ActivityIndicator size={"large"} />
            <Text style={styles.title}>{title}</Text>
        </View>
    );
};

// 关闭
export const closeLoading = () =>{
    if (sibling) sibling.destroy();
};

// CSS样式
const styles = StyleSheet.create({
    loading:{
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        backgroundColor:'#00000055',
    },
    title:{
        fontSize: 18,
        color:'#ffffff',
        marginTop: 10,
    },

});

