/**
 * GSKePan
 * GSDetailsPage.js
 *
 * @author: zengzhiming
 * @date:   2023/3/20
 * @desc:   GSDetailsPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
    FlatList,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import {LanguageType} from "../../Common/Languages/GSLanguages";


class GSDetailsPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);


        this.state = {
            imgUrl:" ",
            dataList:[],
        };
    }


    componentDidMount() {
        this.setNaviRightButton();
        this.getDataList(this.props["language"]);

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps["language"] !== this.props["language"]){
            this.getDataList(nextProps["language"]);
            this.setNaviRightButton();
            return false;
        }

        return true;
    }


    getDataList(language) {

        const dataArr = (language === LanguageType.ZH_HANT)
            ? [{"name":"款式","value":"\"得獎作品\"18K/750金(白色)鑲鑽石耳環壹對"},{"name":"貨品標價","value":"$55,850"},{"name":"櫃號","value":""},{"name":"貨類","value":"J1"},{"name":"顏色","value":"H-I"},{"name":"淨度","value":"VS"},{"name":"貨主","value":"TEG"},{"name":"圈度","value":""},{"name":"形狀","value":""},{"name":"襯石","value":"圓形鑽"},{"name":"玉器尺寸","value":""},{"name":"主石重","value":"0"},{"name":"模號","value":"夜幕"},{"name":"人工","value":"4300"},{"name":"粒數","value":"0"},{"name":"金重","value":"26.95g"},{"name":"對稱","value":""},{"name":"車工","value":""},{"name":"底尖","value":""},{"name":"打磨","value":""},{"name":"熒反","value":""},{"name":"證書編號","value":"VAL118000019","rescertno":""},{"name":"貨品編號","value":"079J1000271"},{"name":"備註","value":"134RBD0.950"},{"name":"貨品提示","value":""},{"name":"工場名稱","value":"J2032"},{"name":"圖片地址","value":"http://yl5.jw28.com/P271/079J1000271.jpg"}]
            : [{"name":"款式","value":"\"得奖作品\"18K/750金(白色)镶钻石耳环壹对"},{"name":"货品标价","value":"$55,850"},{"name":"柜号","value":""},{"name":"货类","value":"J1"},{"name":"颜色","value":"H-I"},{"name":"净度","value":"VS"},{"name":"货主","value":"TEG"},{"name":"圈度","value":""},{"name":"形状","value":""},{"name":"衬石","value":"圆形钻"},{"name":"玉器尺寸","value":""},{"name":"主石重","value":"0"},{"name":"模号","value":"夜幕"},{"name":"人工","value":"4300"},{"name":"粒数","value":"0"},{"name":"金重","value":"26.95g"},{"name":"对称","value":""},{"name":"车工","value":""},{"name":"底尖","value":""},{"name":"打磨","value":""},{"name":"荧反","value":""},{"name":"证书编号","value":"VAL118000019","rescertno":""},{"name":"货品编号","value":"079J1000271"},{"name":"备注","value":"134RBD0.950"},{"name":"货品提示","value":""},{"name":"工场名称","value":"J2032"},{"name":"图片地址","value":"http://yl5.jw28.com/P271/079J1000271.jpg"}];


        const imgInfo = dataArr.pop();
        let dataList = dataArr.filter((item)=>  item.value !== "" && item.value !== "0" )

        this.setState({
            imgUrl: imgInfo["value"],
            dataList: dataList,
        });

    }


    setNaviRightButton() {

        const {Images, Colors} = Theme;

        this.props.navigation.setOptions({
            headerRight: (props) => {
                return (
                    <TouchableOpacity style={{marginRight:8}} onPress={()=>{
                        // this.onBatchAddPress();
                    }}>
                        <Text style={{fontSize:16, color: Colors.navi_000000}}>{T("议价")}</Text>
                    </TouchableOpacity>
                );
            },
        });
    }



    render() {

        const {dataList, imgUrl} = this.state;
        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={styles.top_img_view}>
                            <Image style={styles.top_img} resizeMode={"stretch"} source={{uri:imgUrl}}  />
                        </View>
                        <View style={styles.flat_list_view}>
                            <FlatList
                                data={dataList}
                                renderItem={({item})=>this.renderItem(item)}
                                keyExtractor={(item, index) => index+""}
                            />
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    renderItem(item){

        const styles = this.getStyles();
        const {name, value} = item;

        return (
            <TouchableOpacity style={styles.item_view_btn}>
                <View style={styles.item_content_view}>
                    <View style={styles.item_content_name_view}>
                        <Text style={styles.item_content_name_text}>{name}</Text>
                    </View>
                    <View style={styles.item_content_value_view}>
                        <Text style={styles.item_content_value_text} numberOfLines={1}>{value}</Text>
                    </View>
                </View>
            </TouchableOpacity>
        );
    }

    getStyles() {

        const {Colors}  = Theme;

        return StyleSheet.create({

            safe_area_view:{
                flex: 1,
                backgroundColor:Colors.theme_background,
            },

            container: {
                flex: 1,
            },

            top_img_view:{
                width:"100%",
                aspectRatio: 2,
            },

            top_img:{
                width:"100%",
                height:"100%",
            },

            flat_list_view:{
                flex:1,
                marginTop:8,
            },

            item_view_btn:{
                backgroundColor:Colors.theme_FFFFFF,
                borderBottomColor:Colors.sacn_details_D7D7D7,
                borderBottomWidth:0.5,
            },

            item_content_view:{
                flexDirection:"row",
                paddingHorizontal:18,
            },

            item_content_name_view:{
                width:85,
                height:53,
                justifyContent:"center",
            },

            item_content_value_view:{
                flex:1,
                height:53,
                justifyContent:"center",
            },

            item_content_name_text:{
                fontSize:18,
                color:Colors.sacn_details_777777,
            },

            item_content_value_text:{
                fontSize:18,
                color:Colors.theme_first,
                fontWeight:"bold"
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSDetailsPage)


// export default GSDetailsPage;