/**
 * GSKePan
 * GSLoginPage.js
 *
 * @author: zengzhiming
 * @date:   2023/2/24
 * @desc:   GSLoginPage
 **/
'use strict';

import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
    TextInput,
    ImageBackground,
    UIManager, findNodeHandle
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import UserList from "./View/GSUserListView";
import ScanCode from "./View/GSScanCodeView";

class GSLoginPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            isScan:false,
            isShowUserList:false,
        };

    }

    componentDidMount() {
        // this.setBackPress(()=>{
        //     console.log("====>");
        // });
    }

    onBranchSearchPress() {
        this.push(SceneKey.BranchSearch);
    }

    onLoginButtonPress(){
        this.push(SceneKey.ScanCode, {});
    }


    onScanCodePress(){

        const isScan = !this.state.isScan;

        this.setState({
            isScan: isScan,
        });

        if (isScan) {
            this.scanCodeRef.show();
        } else {
            this.scanCodeRef.hide();
        }

    }


    onUserListPress(){
        const nameList = [{"branchCode":"CW4","userName":"G02334","password":"lf2021"}, {"branchCode":"CW4","userName":"88888","password":"88888"}];

        const isShowUserList = !this.state.isShowUserList;

        this.setState({
            isShowUserList: isShowUserList,
        });

        if (isShowUserList) {
            this.userListRef.show(nameList);
        } else {
            this.userListRef.hide();
        }

    }

    onBarCodeScanned(result) {

        showAlert("扫描结果", result, ["关闭", "继续"], (code, btnTitle)=>{
            if (code === 1) {
                this.scanCodeRef.start();
            } else {
                this.scanCodeRef.hide();
                this.setState({
                    isScan: false,
                });
            }
        })
    };

    onUserListSelectedPress(code,result) {
        console.log(code,result);
    }


    render() {

        const {Images, Colors} = Theme;
        const styles = this.getStyles();

        const nameList = [{"branchCode":"CW4","userName":"G02334","password":"lf2021"}, {"branchCode":"CW4","userName":"88888","password":"88888"}];


        return (
                <View style={{flex: 1}}>
                    <Image style={styles.login_container_bg} source={Images.login_login_bg_def}/>
                    <SafeAreaView style={styles.safe_area_view}>
                        <View style={styles.container}>

                            <View style={{width:"75%"}}>
                                <View style={styles.login_box_view}>
                                    <Image style={styles.login_box_input_icon} source={Images.login_login_branch} />
                                    <View style={styles.login_box_input_view}>
                                        <TextInput style={styles.login_box_input_text}  placeholder={T("分行代码")} placeholderTextColor={Colors.login_login_C5C5C5}/>
                                    </View>
                                    <TouchableOpacity style={styles.login_box_input_btn} onPress={()=>this.onBranchSearchPress()}>
                                        <Image style={styles.login_small_icon}  source={Images.login_login_bank_right} />
                                    </TouchableOpacity>
                                </View>

                                <View ref={refs=>{this.nameRef = refs}} style={styles.login_box_view}>
                                    <Image style={styles.login_box_input_icon} source={Images.login_login_user} />
                                    <View style={styles.login_box_input_view}>
                                        <TextInput style={styles.login_box_input_text} placeholder={T("用 户 名")} placeholderTextColor={Colors.login_login_C5C5C5}/>
                                    </View>
                                    <TouchableOpacity style={styles.login_box_input_btn} onPress={()=>this.onUserListPress()}>
                                        <Image style={styles.login_small_icon} source={Images.login_login_arrow_down} />
                                    </TouchableOpacity>
                                </View>

                                <View>
                                    <View style={styles.login_box_view}>
                                        <Image style={styles.login_box_input_icon} source={Images.login_login_pwd} />
                                        <View style={styles.login_box_input_view}>
                                            <TextInput style={styles.login_box_input_text} placeholder={T("密  码")} placeholderTextColor={Colors.login_login_C5C5C5}/>
                                        </View>
                                        <TouchableOpacity style={styles.login_box_input_btn} onPress={()=>{}}>
                                            <Image style={styles.login_small_icon} source={Images.login_login_pwd_clear} />
                                        </TouchableOpacity>
                                    </View>

                                    <View style={styles.login_box_view}>
                                        <TouchableOpacity style={styles.login_box_login_btn} onPress={()=>this.onLoginButtonPress()}>
                                            <Text style={styles.login_box_login_btn_txt}>{T("登 录")}</Text>
                                        </TouchableOpacity>
                                    </View>

                                    <UserList ulRef={(ulRef)=>this.userListRef=ulRef} dataSource={nameList} onSelectedPress={(code, result)=>{this.onUserListSelectedPress(code, result)}} style={{position:"absolute", top:-2, left:0, bottom:0, right:0}} />
                                </View>

                                <ScanCode scRef={(scRef)=>this.scanCodeRef = scRef} onBarCodeScanned={(result)=>{this.onBarCodeScanned(result)}} style={{position:"absolute", top:23, left:0, bottom:0, right:0}}/>
                            </View>

                            <View style={styles.login_ver_view}>
                                <Text style={styles.login_ver_txt}>{T("Ver:企业版")+"1.5.2"}</Text>
                                <TouchableOpacity style={styles.login_ver_btn} onPress={()=>{this.onScanCodePress()}}>
                                    <Text style={styles.login_ver_btn_txt}>{T("扫码登录")}</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={styles.login_clear_view}>
                                <TouchableOpacity style={styles.login_clear_btn}>
                                    <Image style={styles.login_small_icon} source={Images.login_login_clear_circle}/>
                                    <Text style={styles.login_clear_btn_txt}>{T("清空购物清单")}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </SafeAreaView>
                </View>
        );
    }


    getStyles = () => {

        const {Colors} = Theme;

        return  StyleSheet.create({

            safe_area_view:{
                flex: 1,
                // backgroundColor:Colors.theme_background,
            },

            login_container_bg: {
                position: 'absolute',
                width:"100%",
                height:"100%",
            },

            container: {
                paddingTop: 10,
                alignItems: 'center',
            },

            login_box_view: {
                marginTop: 23,
                height: 53,
                borderWidth: 2,
                borderColor: Colors.theme_first,
                backgroundColor: Colors.login_login_FFFFFF,
                flexDirection: 'row',
                alignItems: 'center',
                overflow: 'hidden',
            },

            login_small_icon: {
                width: 28,
                height: 28,
            },

            login_box_input_icon:{
                marginLeft:8,
                marginRight:8,
                width:28,
                height:28,
            },

            login_box_input_view:{
                flex:1,
                height:"100%",
                overflow: 'hidden',
            },

            login_box_input_text:{
                flex:1,
                height:"100%",
                fontSize:19,
                margin:-1,
                padding:1,
                color:Colors.theme_000000,
            },

            login_box_input_btn:{
                marginLeft:8,
                marginRight:8,
            },

            login_box_login_btn:{
                width:"100%",
                height:"100%",
                alignItems: 'center',
                justifyContent:"center",
                backgroundColor: Colors.theme_first,
            },

            login_box_login_btn_txt:{
                fontSize:23,
                color:"white",
            },

            login_ver_view:{
                marginTop:15,
                width: "75%",
                flexDirection:'row',
                justifyContent: "space-between",
            },

            login_ver_txt:{
                fontSize:18,
                fontWeight:"bold",
                color:Colors.login_login_000000,
            },

            login_ver_btn:{
                borderBottomColor: Colors.login_login_007BFE,
                borderBottomWidth: 1,
            },

            login_ver_btn_txt:{
                color: Colors.login_login_007BFE,
                fontSize:18,
            },

            login_clear_view:{
                marginTop:24,
                width: "75%",
                alignItems: 'center',
            },

            login_clear_btn:{
                flexDirection:'row',
                paddingTop:3,
                paddingBottom:3,
                paddingLeft:15,
                paddingRight:15,
                alignItems: 'center',
                justifyContent:"center",
                borderWidth:1,
                borderColor:Colors.theme_first,
                backgroundColor:Colors.login_login_F1E0DA,
                borderRadius:16,
            },

            login_clear_btn_txt:{
                color:"#732043",
                fontSize: 19,
                marginLeft: 5,
            },
        });
    }
}



export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSLoginPage)

// export default GSLoginPage;
