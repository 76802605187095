/**
 * GSKePan
 * GSHttpManager.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:20
 * @desc:   GSHttpManager
 **/
'use strict';


import {spliceURIEncodeString} from '../Utils/GSSpliceURI';



/** HttpPost请求
 *
 * uristr string URI字符串
 * params object 参数
 * datas object 上传数据 {"uploadFiles":[{'uri': imgUri, 'type': 'image/jpeg', 'name': 'image.jpeg'}, {'uri': imgUri, 'type': 'image/jpeg', 'name': 'image.jpeg'}]}
 */
export const httpPostManager = (uristr = '', params = {}, datas = {}) => {

    // 判断uri是否为空
    if (isEmptyString(uristr)) {
        return Promise.reject(new Error('The request address is empty'));
    }

    // 添加参数
    let formData = new FormData();
    for (let key in params){
        formData.append(key, params[key]);
    }

    // 添加上传数据
    for (let key in datas){
        const data = datas[key];
        if (!isEmptyString(data["uri"])) {
            formData.append(key, data);
        } else {
            for (let i in data){
                formData.append(key+"[]", data[i]);
            }
        }
    }

    // 网络请求
    return fetch(uristr, {
        method: 'POST',
        body: formData,
        // headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify(params),

    }) .then(
        (response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Network request error: ' + response.status);
            }
        },
        (error)=>{
            throw error;
        }
    ).catch(
        (error)=>{
            throw error;
        }
    );
};


/** HttpGet请求
 *
 * uristr string URI字符串
 * params object 参数
 */
export const httpGetManager = (uristr = '', params = {}) => {

    // 判断uri是否为空
    if (isEmptyString(uristr)) {
        return Promise.reject(new Error('The request address is empty'));
    }

    // 添加参数
    if (!isEmptyObject(params)) {
        uristr = spliceURIEncodeString(uristr, params);
    }

    // 网络请求
    return fetch(uristr, {
        method: 'GET',
    }) .then(
        (response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Network request error: ' + response.status);
            }
        },
        (error)=>{
            throw error;
        }
    ).catch(
        (error)=>{
            throw error;
        }
    );
};
