/**
 * GSKePan
 * GSNavigation.js
 *
 * @author: zengzhiming
 * @date:   2022/5/20 12:39
 * @desc:   GSNavigation
 **/
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { NavigationContainer, DarkTheme, DefaultTheme } from "@react-navigation/native";
import { createStackNavigator } from '@react-navigation/stack';
// import {HeaderBackButton} from '@react-navigation/elements';

import Brand from '../Main/Login/GSBrandPage'
import Login from '../Main/Login/GSLoginPage'
import BranchSearch from '../Main/Login/GSBranchSearchPage'

import ScanCode from '../Main/Scan/GSScanCodePage'
import OrderProcessed from '../Main/Scan/GSOrderProcessedPage'
import Details from '../Main/Scan/GSDetailsPage'

import MainTest from '../Main/GSTestPage'
import Test from '../Test/GSTestPage'



// SceneKey
export const SceneKey = {
    Brand:'Brand',
    Login:'Login',
    BranchSearch:'BranchSearch',

    ScanCode:'ScanCode',
    OrderProcessed:'OrderProcessed',
    Details:'Details',

    MainTest:'MainTest',
    Test:'Test',
};



export function GSNavigation(state) {

    const Stack = createStackNavigator();
    const theme = state["theme"] === ThemeType.DARK ? DarkTheme : DefaultTheme
    const {Colors} = Theme;

    return (
        <NavigationContainer theme={theme}>
            <Stack.Navigator
                initialRouteName={SceneKey.Brand}
                screenOptions={({navigation})=>({
                    headerTitleAlign:"center",
                    animation: "slide_from_right", // 划入方式
                    headerShown: true, // 不要隐藏标题
                    headerBackTitle:T("返回"),
                    headerTintColor:Colors.navi_000000,
                    headerStyle:{backgroundColor:Colors.navi_FFFFFF},
                })}
            >

                <Stack.Screen
                    name={SceneKey.Brand}
                    component={Brand}
                    options={{
                        title: T("品牌"),
                        headerShown: true,
                        headerLeft:null, // 隐藏返回按钮
                    }}
                />

                <Stack.Screen
                    name={SceneKey.BranchSearch}
                    component={BranchSearch}
                    options={{
                        title: T("选择分行"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.Login}
                    component={Login}
                    options={{
                        title: T("登录"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.ScanCode}
                    component={ScanCode}
                    options={{
                        title: T("二维码/条形码"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.OrderProcessed}
                    component={OrderProcessed}
                    options={{
                        title: T("订单信息"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.Details}
                    component={Details}
                    options={{
                        title: T("商品详情"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.MainTest}
                    component={MainTest}
                    options={{
                        title: T("测试"),
                    }}
                />

                <Stack.Screen
                    name={SceneKey.Test}
                    component={Test}
                    options={{
                        title: T("测试"),
                    }}
                />

            </Stack.Navigator>

        </NavigationContainer>
    );
}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSNavigation)

