/**
 * GSKePan
 * GSLocalization.js
 *
 * @author: zengzhiming
 * @date:   2023/3/10
 * @desc:   GSLocalization
 **/
'use strict';

import { getLocales, getCalendars } from 'expo-localization';

let calendars = getCalendars()[0];

let locales = getLocales()[0];


/**
 * 更新本地化数据
 **/
export const updateLocalization = () => {
    locales = getLocales()[0];
    calendars = getCalendars()[0];
}



/**
 * 日历标识符，Unicode日历类型之一
 * 例：gregory
 **/
export const calendar = calendars.calendar;

/**
 * 一周的第一天
 * 例：1，7
 **/
export const firstWeekday = calendars.firstWeekday;

/**
 * 日历的时区。
 * 例："Asia/Shanghai",'Europe/Warsaw','GMT+1'
 **/
export const timeZone = calendars.timeZone;

/**
 * 当前设备设置使用24小时时间格式时为true
 * false / true
 **/
export const uses24hourClock = calendars.uses24hourClock;



/**
 * 货币代码
 * 例：'USD','EUR','PLN'
 **/
export const currencyCode = locales.currencyCode;

/**
 * 货币符号
 * 例：'$', '€', 'zł'
 **/
export const currencySymbol = locales.currencySymbol;

/**
 * 小数点分隔符
 * 例：'.'
 **/
export const decimalSeparator = locales.decimalSeparator;

/**
 * 千位分隔符
 * 例：","
 **/
export const digitGroupingSeparator = locales.digitGroupingSeparator;

/**
 * 语言代码
 * 例：'en', 'es', "zh"
 **/
export const languageCode = locales.languageCode;

/**
 * 语言标签
 * 例：'en-US''es-419'，'zh-Hans-US'
 **/
export const languageTag = locales.languageTag;

/**
 * 测量系统
 * 例：在iOS上是'metric'”、“'us'之一。在Android上是'metric'”、“'us'”、“'uk'之一。在web上是null
 **/
export const measurementSystem = locales.measurementSystem;

/**
 * 区域代码
 * 例： "US", web为null
 **/
export const regionCode = locales.regionCode;

/**
 * 文本方向
 * 例：'ltr'，'rtl'
 **/
export const textDirection = locales.textDirection;


export {locales, calendars};


// getCalendars =====> [{"calendar": "gregory", "firstWeekday": 1, "timeZone": "Asia/Shanghai", "uses24hourClock": false}]
// getLocales   =====> [{"currencyCode": null, "currencySymbol": "¤", "decimalSeparator": ".", "digitGroupingSeparator": ",", "languageCode": "en", "languageTag": "en", "measurementSystem": "us", "regionCode": null, "textDirection": "ltr"}]