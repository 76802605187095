/**
 * GSKePan
 * GSBrandPage.js
 *
 * @author: zengzhiming
 * @date:   2023/2/24
 * @desc:   GSBrandPage
 **/
'use strict';


import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Platform,
    ImageBackground, Image,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import { VictoryPie } from 'victory-native';
import {LanguageType} from "../../Common/Languages/GSLanguages";

class GSBrandPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            selBrand:{},
            brandList:[]
        };
    }

    componentDidMount() {
        this.getBrandList(this.props["language"]);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps["language"] !== this.props["language"]){
            this.getBrandList(nextProps["language"]);
            return false;
        }
        return true;
    }

    getBrandList = (language) => {
        const brandList = (language === LanguageType.ZH_HANT)
            ? [{"x":"1","y":1,"label":"六福珠寶"},{"x":"2","y":1,"label":"金至尊"},{"x":"3","y":1,"label":"永和"},{"x":"4","y":1,"label":"新喜"},{"x":"5","y":1,"label":"新放昌"},{"x":"6","y":1,"label":"寰昌"},{"x":"7","y":1,"label":"榮昌"},{"x":"8","y":1,"label":"其他"}]
            : [{"x":"1","y":1,"label":"六福珠宝"},{"x":"2","y":1,"label":"金至尊"},{"x":"3","y":1,"label":"永和"},{"x":"4","y":1,"label":"新喜"},{"x":"5","y":1,"label":"新放昌"},{"x":"6","y":1,"label":"寰昌"},{"x":"7","y":1,"label":"荣昌"},{"x":"8","y":1,"label":"其他"}]

        this.setState({
            brandList: brandList,
        });
    }

    eventHandlers = (dataList) => {

        const onClick = (event, conf, index) => {
            const selBrand = dataList[index];
            this.setState({
                selBrand:selBrand,
            });

            setTimeout(()=>{
                this.push(SceneKey.Login,{
                    brand: selBrand
                })
            },300);

            // console.log('onPress===>', selBrand);
        }

        // 适配Web点击事件
        return  Platform.OS ==="web" ?  {onClick : onClick} : {onPress : onClick}

    }

    render() {
        const styles = this.getStyles();
        const {brandList, selBrand} = this.state;
        const {Images, Colors} = Theme;

        let colorScale = brandList.map(item=>{
            return item["label"] === selBrand["label"] ? Colors.theme_second : Colors.theme_first;
        });

        return (
            <View style={{flex: 1}}>
                <Image style={styles.bg_img} source={Images.login_brand_bg}/>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <VictoryPie
                            padding={30}
                            data={brandList}
                            colorScale={colorScale}
                            labelRadius={({ innerRadius }) => 80 }
                            style={{
                                labels: {fill: Colors.login_brand_FFFFFF, fontSize: 18, fontWeight:"bold"},
                                data: {stroke:Colors.login_brand_FFAFB0, strokeWidth: 3}
                            }}
                            innerRadius={70}
                            padAngle={({ datum }) => 2}
                            labelPlacement={() => "parallel"} // 可选parallel、vertical
                            events={[
                                {
                                    // childName: 'all',	// 可选all或者子组件的name
                                    "target": 'data',     // 可选parent、data、labels
                                    "eventHandlers": this.eventHandlers(brandList)
                                },
                                {
                                    // childName: 'all',	// 可选all或者子组件的name
                                    "target": 'labels',     // 可选parent、data、labels
                                    "eventHandlers": this.eventHandlers(brandList)
                                },
                            ]}
                        />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles = () => {

        const {Colors} = Theme;

        return  StyleSheet.create({

            safe_area_view:{
                flex: 1,
                backgroundColor:Colors.theme_background,
            },

            container: {
                width:"100%",
                height:"80%",
                justifyContent: 'center',
                alignItems: 'center',
            },

            bg_img: {
                position: 'absolute',
                width:"100%",
                height:"100%",
            },

        });
    }

}

export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSBrandPage)

// export default GSBrandPage;
