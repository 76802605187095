/**
 * GSKePan
 * GSOrderProcessedPage.js
 *
 * @author: zengzhiming
 * @date:   2023/3/20
 * @desc:   GSOrderProcessedPage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    SectionList,
    Image,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import { formatCurrencyMine } from "../../Common/Utils/GSStringFormat"
import {LanguageType} from "../../Common/Languages/GSLanguages";

class GSOrderProcessedPage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {
            dataList: [],
        };
    }

    componentDidMount() {
        this.getDataList(this.props["language"]);

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps["language"] !== this.props["language"]){
            this.getDataList(nextProps["language"]);
            return false;
        }

        return true;
    }


    getDataList(language) {

        const dataArr = (language === LanguageType.ZH_HANT)
            ? [{"InvoiceNo":"003_1","invtime":"2023-03-21 09:34:22","totPrice":"141650.000","ITEMS":[{"itemcode":"079J1000271","codename":"\"得獎作品\"18K/750金(白色)鑲鑽石耳環壹對","Path":"http://yl5.jw28.com/P271/079J1000271.jpg","oriPrice":"55850.00","PRICES":"55850.00"},{"itemcode":"088J1J09151","codename":"18K/750金(白色)鑲鑽石頸鏈壹條","Path":"http://yl5.jw28.com/P151/088J1J09151.jpg","oriPrice":"85800.00","PRICES":"85800.00"}]},{"InvoiceNo":"002_1","invtime":"2023-03-21 09:33:22","totPrice":"55851.000","ITEMS":[{"itemcode":"079J1000271","codename":"\"得獎作品\"18K/750金(白色)鑲鑽石耳環壹對","Path":"http://yl5.jw28.com/P271/079J1000271.jpg","oriPrice":"55850.00","PRICES":"55850.00"}]},{"InvoiceNo":"001_1","invtime":"2023-03-21 09:33:08","totPrice":"85801.000","ITEMS":[{"itemcode":"088J1J09151","codename":"18K/750金(白色)鑲鑽石頸鏈壹條","Path":"http://yl5.jw28.com/P151/088J1J09151.jpg","oriPrice":"85800.00","PRICES":"85800.00"}]}]
            : [{"InvoiceNo":"003_1","invtime":"2023-03-21 09:34:22","totPrice":"141650.000","ITEMS":[{"itemcode":"079J1000271","codename":"\"得奖作品\"18K/750金(白色)镶钻石耳环壹对","Path":"http://yl5.jw28.com/P271/079J1000271.jpg","oriPrice":"55850.00","PRICES":"55850.00"},{"itemcode":"088J1J09151","codename":"18K/750金(白色)镶钻石颈链壹条","Path":"http://yl5.jw28.com/P151/088J1J09151.jpg","oriPrice":"85800.00","PRICES":"85800.00"}]},{"InvoiceNo":"002_1","invtime":"2023-03-21 09:33:22","totPrice":"55851.000","ITEMS":[{"itemcode":"079J1000271","codename":"\"得奖作品\"18K/750金(白色)镶钻石耳环壹对","Path":"http://yl5.jw28.com/P271/079J1000271.jpg","oriPrice":"55850.00","PRICES":"55850.00"}]},{"InvoiceNo":"001_1","invtime":"2023-03-21 09:33:08","totPrice":"85801.000","ITEMS":[{"itemcode":"088J1J09151","codename":"18K/750金(白色)镶钻石颈链壹条","Path":"http://yl5.jw28.com/P151/088J1J09151.jpg","oriPrice":"85800.00","PRICES":"85800.00"}]}];
        const dataList = JSON.parse(JSON.stringify(dataArr).replace(/"ITEMS"/g,'"data"'));

        this.setState({
            dataList: dataList,
        });

    }


    render() {

        const {dataList} = this.state;

        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <SectionList
                            sections={dataList}
                            keyExtractor={(item, index) => index+""}
                            renderItem={({ item, index, section}) => this.itemRow(item, index, section)}
                            renderSectionHeader={({ section }) => this.itemHeader(section)}
                        />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    itemHeader(section) {
        const styles = this.getStyles();
        const totPrice = formatCurrencyMine(section["totPrice"]);
        return (
            <View style={styles.section_header_view}>
                <Text style={styles.section_header_invoiceNo}>{section["InvoiceNo"]}</Text>
                <Text style={styles.section_header_invtime}>{section["invtime"]}</Text>
                <Text style={styles.section_header_totPrice}>{totPrice}</Text>
            </View>
        );
    }

    itemRow(item, index, section) {

        const oriPrice = formatCurrencyMine(item["oriPrice"]);
        const prices = formatCurrencyMine(item["PRICES"]);

        const styles = this.getStyles();

        return (
            <TouchableOpacity style={styles.section_item_view}>
                <View style={styles.section_item_img_view}>
                    <Image style={styles.section_item_img} source={{uri:item["Path"]}}/>
                </View>
                <View style={styles.section_item_name_view}>
                    <Text style={styles.section_item_name_codename} numberOfLines={1}>{item["codename"]}</Text>
                    <Text style={styles.section_item_name_itemcode} >{item["itemcode"]}</Text>
                </View>
                <View>
                    <View>
                        <Text style={styles.section_item_price_oriPrice}>{oriPrice}</Text>
                        <View style={styles.section_item_price_oriPrice_del}/>
                    </View>
                    <Text style={styles.section_item_price_prices}>{prices}</Text>
                </View>
            </TouchableOpacity>
        );
    }

    getStyles() {

        const {Colors}  = Theme;

        return StyleSheet.create({

            safe_area_view:{
                flex: 1,
                backgroundColor:Colors.theme_background,
            },

            container: {
                flex: 1,
            },

            section_header_view:{
                flexDirection:"row",
                backgroundColor:Colors.theme_FFFFFF,
                paddingHorizontal:18,
                paddingVertical:14,
                marginTop:10,
            },

            section_header_invoiceNo:{
                fontSize:16,
                color:Colors.theme_000000,
            },

            section_header_invtime:{
                fontSize:16,
                color:Colors.theme_000000,
                marginLeft:28,
                flex:1,
            },

            section_header_totPrice:{
                fontSize:16,
                color:Colors.sacn_order_processed_FF9D62,
            },

            section_item_view:{
                backgroundColor:Colors.theme_FFFFFF,
                flexDirection:"row",
                paddingVertical:5,
                paddingHorizontal:18,
                borderTopColor:Colors.sacn_order_processed_D7D7D7,
                borderTopWidth:0.5,
                alignItems:"center",
            },

            section_item_img_view:{
                padding:5,
                backgroundColor:Colors.sacn_order_processed_F3F3F3,
            },

            section_item_img:{
                width:50,
                height:50,
            },

            section_item_name_view:{
                flex:1,
                marginHorizontal:8,
            },

            section_item_name_codename:{
                color:Colors.theme_000000,
                fontSize:15,
            },

            section_item_name_itemcode:{
                color:Colors.sacn_order_processed_777777,
                fontSize:14,
                marginTop:12,
            },

            section_item_price_oriPrice:{
                color:Colors.sacn_order_processed_777777,
                fontSize:16,
            },

            section_item_price_oriPrice_del:{
                height:1,
                backgroundColor:Colors.sacn_order_processed_777777,
                position:"absolute",
                left:0,
                right:0,
                top:10,
            },

            section_item_price_prices:{
                color:Colors.theme_first,
                fontSize:16,
                marginTop:10,
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSOrderProcessedPage)


// export default GSOrderProcessedPage;