/**
 * GSKePan
 * GSActionSheet.js
 *
 * @author zengzhiming
 * @date   2019/1/24
 * @desc:   GSActionSheet
 **/

"use strict";


import React from 'react';
import {StyleSheet, Text, View, TouchableOpacity, Animated, FlatList} from 'react-native';
import RootSiblings from "react-native-root-siblings";
import {getScreenHeight} from "./GSFrame";


let sibling = null;
let marginBottomValue = new Animated.Value(-getScreenHeight());

/**
 * 显示ActionSheet
 *
 * listBtn 按钮列表
 * cancelBtn 取消按钮
 * clickBlock 点击回调
 **/
export const showActionSheet = (listBtn = [], cancelBtn='', clickBlock) => {

    // 空值判断
    if (isEmptyObject(listBtn)){
        return;
    }

    const height = listBtn.length > 6 ? 44*6+22 : null;

    // 渲染 AlertView
    sibling = new RootSiblings(
        <TouchableOpacity style={styles.container} activeOpacity={1} onPress={hideActionSheet}>
            <Animated.View style={[styles.action_view,{bottom: marginBottomValue}]}>
                <View style={styles.btn_list_view}>
                    <FlatList
                        style={{height:height, backgroundColor:"white"}}
                        data={listBtn}
                        showsVerticalScrollIndicator={false}
                        renderItem={({ item, index }) => {
                            return(
                                <View key={index} style={{backgroundColor:"black"}}>
                                    <TouchableOpacity style={styles.btn_list_btn} activeOpacity={0.8} onPress={()=>{
                                        hideActionSheet();
                                        clickBlock && clickBlock(index, item)
                                    }}>
                                        <Text style={styles.btn_text}>{item}</Text>
                                    </TouchableOpacity>
                                </View>
                            );
                        }}
                        keyExtractor={(item, index) => index}
                    />
                </View>
                {
                    !isEmptyString(cancelBtn) &&
                    <View style={styles.btn_view}>
                        <TouchableOpacity style={styles.btn_cancel} activeOpacity={0.8} onPress={hideActionSheet}>
                            <Text style={styles.btn_text}>{cancelBtn}</Text>
                        </TouchableOpacity>
                    </View>
                }
            </Animated.View>
        </TouchableOpacity>
    );

    Animated.timing(
        marginBottomValue,
        {
            toValue: 0,
            duration: 300,
            useNativeDriver: false,
        }
    ).start();

};


// 关闭
export const hideActionSheet = () =>{

    if (marginBottomValue._value !== 0) {
        return;
    }

    Animated.timing(
        marginBottomValue,
        {
            toValue: -getScreenHeight(),
            duration: 300,
            useNativeDriver: false,
        }
    ).start(() => {
        sibling && sibling.destroy();
    });
};


// CSS样式
const styles = StyleSheet.create({
    container:{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        backgroundColor:'#00000055',
    },
    action_view:{
        left: 0,
        right: 0,
        position: 'absolute',
    },
    btn_list_view:{
        marginRight: 8,
        marginLeft: 8,
        marginBottom: 8,
        borderRadius: 8,
        overflow:'hidden',
    },
    btn_list_btn:{
        height:44,
        borderBottomWidth: 0.5,
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomColor: "#DDDDDD",
        backgroundColor:'#FFFFFF',
    },
    btn_view:{
        height:44,
        marginRight: 8,
        marginLeft: 8,
        marginBottom: 8,
        borderRadius:8,
        backgroundColor:'black',
        overflow:'hidden',
    },
    btn_cancel:{
        height:44,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'#FFFFFF',
    },
    btn_text:{
        fontSize: 18
    }

});
