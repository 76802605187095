/**
 * GSKePan
 * colors.js
 *
 * @author zengzhiming
 * @date   2019/1/18
 **/
"use strict";


export const Colors = {

    navi_000000:"#FFFFFF",
    navi_FFFFFF:"#1B1B1B",

    theme_FFFFFF:"#1B1B1B",
    theme_000000:"#FFFFFF",
    theme_background:"#131313",

    login_brand_FFAFB0:"#AD5153",

    login_login_FFFFFF:"#131313",
    login_login_000000:"#999999",

    login_brand_search_F4F6F8:"#222222",
    login_brand_search_EDECEC:"#131313",
    login_brand_search_D7D7D7:"#333333",
    login_brand_search_000000:"#FFFFFF",

    sacn_scancode_info_E57750:"#131313",
    sacn_scancode_info_5D5D60:"#1B1B1B",
    sacn_scancode_info_FFFFFF:"#131313",

    sacn_details_D7D7D7:"#333333",
    sacn_details_777777:"#FFFFFF",

    sacn_order_processed_D7D7D7:"#333333",
    sacn_order_processed_777777:"#B2B2B2",
};

export default Colors;

