/**
 * GSKePan
 * GSStorageSync.js
 *
 * @author: zengzhiming
 * @date:   2022/5/24 16:02
 * @desc:   GSStorageSync
 **/
'use strict';

// import {getDrawerListData, getBoxListData, getProgressListData, getTemplateList, getSpecialCharList, getBrandListData} from '../Http/GSHttpRequest'

// 配置过期时间1分钟
const expires = 1000*60*1;


// 存储Key
export const StorageKey = {
    USER_PWD:      'UserPwd',
    USER_INFO:      'UserInfo',
};

export const storageSync = {
    // sync方法的名字必须和所存数据的key完全相同
    // 参数从params中解构取出
    // 最后返回所需数据或一个promise
    TestData(params) {
        return new Promise((resolve, reject)=>{
            // 异步获取数据
            let syncData = 'StorageSync数据';

            // 保存新数据
            storageSave(StorageKey.TEST_DATA, syncData);

            // 成功则调用resolve
            resolve(syncData);
        });
    },

    // // 获取侧边栏数据
    // DrawerListData(params){
    //
    //     const {syncParams: { callBack }} = params;
    //
    //     return new Promise((resolve, reject)=>{
    //         getDrawerListData(
    //             (code, data)=>{
    //                 // 保存新数据
    //                 storageSave(StorageKey.DRAWER_LIST, data, expires);
    //                 // 成功则调用resolve
    //                 resolve(data);
    //                 callBack && callBack (1, data);
    //             },
    //             (code, data)=>{
    //                 reject(data);
    //                 callBack && callBack (-1, data);
    //             }
    //         );
    //     });
    // },


};
