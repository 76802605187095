/**
 * GSKePan
 * GSScanCodePage.js
 *
 * @author: zengzhiming
 * @date:   2023/3/20
 * @desc:   GSScanCodePage
 **/
'use strict';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
    TextInput,
} from 'react-native';
import BasePage from "../../Common/Component/BaseComponent/GSBasePage";
import ScanCode from "./View/GSScanCodeView";



class GSScanCodePage extends BasePage {

    // 构造函数
    constructor(props, context) {
        super(props, context);

        this.state = {

        };
    }

    componentDidMount() {
        // 页面显示监听
        this.focusListener = this.props.navigation.addListener('focus', (e) => {
            this.scanCodeRef.show();
        });
        // 页面不显示监听
        this.blurListener = this.props.navigation.addListener('blur', (e) => {
            this.scanCodeRef.hide();
        });

        this.setNaviRightButton();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setNaviRightButton();
    }

    componentWillUnmount() {
        // 移除监听
        this.focusListener();
        this.blurListener();
    }

    setNaviRightButton() {

        const {Images, Colors} = Theme;

        this.props.navigation.setOptions({
            headerRight: (props) => {
                return (
                    <View style={{flexDirection:"row"}}>
                        <TouchableOpacity style={{marginRight:8}} onPress={()=>{
                            // this.onBatchAddPress();

                        }}>
                            <Image style={{width: 28, height: 28,}} source={Images.sacn_scancode_shopping} />
                        </TouchableOpacity>
                        <TouchableOpacity style={{marginRight:8}} onPress={()=>{
                            // this.onBatchAddPress();
                        }}>
                            <Image style={{width: 28, height: 28,}} source={Images.sacn_scancode_more} />
                        </TouchableOpacity>
                    </View>
                );
            },
        });
    }


    onBarCodeScanned(result) {

        showAlert("扫描结果", result, ["关闭", "继续"], (code, btnTitle)=>{
            if (code === 1) {
                this.scanCodeRef.start();
            } else {
                this.scanCodeRef.hide();
            }
        })
    };

    onOrderProcessedPress() {
        this.push(SceneKey.OrderProcessed);
    };

    onSearchPress(){
        this.push(SceneKey.Details);
    }


    render() {

        const {Images, Colors} = Theme;
        const styles = this.getStyles();

        return (
            <View style={{flex: 1}}>
                <SafeAreaView style={styles.safe_area_view}>
                    <View style={styles.container}>
                        <View style={styles.user_info_view}>
                            <View style={styles.user_info_left_view}>
                                <Text style={styles.user_info_text}>{T("欢迎您：")+"無名氏（YL5）"}</Text>
                                <Text style={styles.user_info_text}>{T("所在部门：")+"測試部"}</Text>
                                <Text style={styles.user_info_text}>{T("提示信息：")+"密碼今天過期"}</Text>
                            </View>
                            <View style={styles.user_info_right_view}>
                                <Text style={styles.user_info_text}>{T("订单处理中")}</Text>
                                <TouchableOpacity style={styles.user_order_btn} onPress={()=>{this.onOrderProcessedPress()}}>
                                    <Text style={styles.user_order_btn_text}>{"1/2"}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.scan_view}>
                            <ScanCode scRef={(scRef)=>this.scanCodeRef = scRef} onBarCodeScanned={(result)=>{this.onBarCodeScanned(result)}} style={styles.scan_scancode_view}/>
                            <View style={styles.scan_hint_view}>
                                <Text style={styles.scan_hint_text}>{T("将货品二维码/条形码放入框内，即可自动扫描")}</Text>
                            </View>
                        </View>
                        <View style={styles.search_view}>
                            <View style={styles.search_bar_view}>
                                <View style={styles.search_bar_textinput_view}>
                                    <TextInput
                                        placeholder={T("请输入货品编号")}
                                        placeholderTextColor={"#B2B2B2"}
                                        style={styles.search_bar_textinput} />
                                </View>
                                <TouchableOpacity style={styles.search_bar_btn} onPress={()=>{this.onSearchPress()}}>
                                    <Text style={styles.search_bar_btn_txt}>{T("查 询")}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

    getStyles() {

        const {Images, Colors} = Theme;

        return StyleSheet.create({
            safe_area_view:{
                flex: 1,
                backgroundColor:Colors.sacn_scancode_info_5D5D60
            },
            container: {
                flex: 1
            },
            user_info_view:{
                backgroundColor:Colors.sacn_scancode_info_E57750,
                flexDirection:"row",
            },
            user_info_left_view:{
                flex:1,
                marginLeft:24,
                marginBottom:18,

            },
            user_info_right_view:{
                marginRight:24,
            },
            user_info_text:{
                color:"#FFFFFF",
                fontSize:15,
                marginTop:18,
            },
            user_order_btn:{
                borderWidth:0.5,
                borderColor:"#FFFFFF",
                borderRadius:5,
                overflow:"hidden",
                alignItems:"center",
                marginTop:8,
            },
            user_order_btn_text:{
                color:"#FFFFFF",
                fontSize:15,
            },
            scan_view:{
                flex:1,
                backgroundColor:"black",
            },
            scan_scancode_view:{
                position:"absolute",
                top:0,
                left:0,
                bottom:0,
                right:0,
            },
            scan_hint_view:{
                alignItems:"center",
                position:"absolute",
                left:0,
                right:0,
                bottom:70,
            },
            scan_hint_text:{
                color:"#FFFFFF",
                fontSize:13,
            },
            search_view:{
                backgroundColor:Colors.sacn_scancode_info_5D5D60,
            },
            search_bar_view:{
                height:53,
                marginVertical:42,
                marginHorizontal:22,
                flexDirection:"row",
            },
            search_bar_textinput_view:{
                flex:1,
                height:"100%",
                overflow: 'hidden',
            },
            search_bar_textinput:{
                flex:1,
                height:"100%",
                fontSize:21,
                margin:-3,
                padding:3,
                backgroundColor:Colors.sacn_scancode_info_FFFFFF,
                borderRadius:8,
                overflow:"hidden",
                paddingLeft:8,
                color:Colors.theme_000000,
            },
            search_bar_btn:{
                height:"100%",
                width:85,
                backgroundColor:"#FF6162",
                alignItems:"center",
                justifyContent:"center",
                marginLeft:3,
            },
            search_bar_btn_txt:{
                color:"#FFFFFF",
                fontSize:21
            },
        })
    }

}


export default connect((state) => {
        return ({
            language: state.language,
            theme: state.theme,
        })
    }
)(GSScanCodePage)


// export default GSScanCodePage;