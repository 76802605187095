/**
 * GSKePan
 * index.js
 *
 * @author zengzhiming
 * @date   2019/1/18
 **/
"use strict";


export const Colors = {

    navi_000000:"#000000",
    navi_FFFFFF:"#FFFFFF",


    theme_first:"#FF6162",
    theme_second:"#E57750",
    theme_background:"#F2F1F6",

    theme_FFFFFF:"#FFFFFF",
    theme_000000:"#000000",


    login_brand_FFFFFF:"#FFFFFF",

    login_brand_FFAFB0:"#FFAFB0",


    login_login_C5C5C5:"#C5C5C5",
    login_login_007BFE:"#007BFE",
    login_login_F1E0DA:"#F1E0DA",

    login_login_FFFFFF:"#FFFFFF",
    login_login_000000:"#000000",


    login_brand_search_B2B2B2:"#B2B2B2",

    login_brand_search_FFFFFF:"#FFFFFF",
    login_brand_search_F4F6F8:"#F4F6F8",
    login_brand_search_EDECEC:"#EDECEC",
    login_brand_search_D7D7D7:"#D7D7D7",
    login_brand_search_000000:"#000000",

    sacn_scancode_info_E57750:"#E57750",
    sacn_scancode_info_5D5D60:"#5D5D60",
    sacn_scancode_info_FFFFFF:"#FFFFFF",

    sacn_details_D7D7D7:"#D7D7D7",
    sacn_details_777777:"#777777",

    sacn_order_processed_FF9D62:"#FF9D62",
    sacn_order_processed_F3F3F3:"#F3F3F3",

    sacn_order_processed_D7D7D7:"#D7D7D7",
    sacn_order_processed_777777:"#777777",


};

export default Colors;