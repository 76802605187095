/**
 * GSKePan
 * GSFrame.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:28
 * @desc:   GSFrame
 **/
'use strict';

import {Dimensions} from  'react-native';


// 获取屏幕高度
export const getScreenHeight = () => {
    return getScreenSize().screenHeight;
};


// 获取屏幕宽度
export const getScreenWidth = () => {
    return getScreenSize().screenWidth;
};


// 获取屏幕大小
export const getScreenSize = () => {
    const {width , height}  = Dimensions.get('window');
    const screenWidth = width < height ? width : height;
    const screenHeight = width > height ? width : height;
    return {screenWidth, screenHeight};
};
