/**
 * GSKePan
 * GSHttpRequest.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:21
 * @desc:   GSHttpRequest
 **/
'use strict';

import {
    httpPostFilter,
    httpGetFilter,
    HttpCode,
    httpUploadFilter,
    createUploadFile,
    createUploadBase64File,
} from './GSHttpFilter'
import {httpPostManager} from "./GSHttpManager";
import {spliceURILogString} from "../Utils/GSSpliceURI";

const DNS_URL = 'http://zijiyi.qirimi.cn';
// const DNS_URL = 'http://192.168.4.108:4108';




/** 获取商店卡包信息
 *
 * success function 成功回调
 * failure function 失败回调
 */
export const getStoreCardBagInfo = (storeBagId, success, failure) => {

    const urlStr = DNS_URL+"/zijiyi/CardManage/getStoreCardBagInfo";
    const params = {
        "storeBagId":storeBagId,
    };
    httpPostFilter(urlStr, params, success, failure)
};


