/**
 * GSKePan
 * index.js
 *
 * @author: zengzhiming
 * @date:   2023/3/9
 * @desc:   index
 **/
'use strict';

const Images = {
    app_splash:require('./app_splash.png'),
    login_brand_bg:require('./login_brand_bg.png'),

    login_login_bg_def:require('./login_login_bg_def.png'),

    sacn_scancode_shopping:require('./sacn_scancode_shopping.png'),
    sacn_scancode_more:require('./sacn_scancode_more.png'),

}

export default Images;