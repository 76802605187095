/**
 * GSKePan
 * GSDoublePress.js
 *
 * @author: zengzhiming
 * @date:   2022/4/27 17:27
 * @desc:   GSDoublePress
 **/
'use strict';

let lastTimeArray = [];
export const isDoublePress = (pressId = 0, interval = 1000) => {

    let lastPressTime = lastTimeArray[pressId];
    if (lastPressTime === undefined) lastPressTime = 0;

    // 判断双击
    let timestamp = (new Date()).valueOf();
    if (timestamp - lastPressTime < interval) {
        return true;
    }
    lastTimeArray[pressId] = timestamp;

    return false;
};

